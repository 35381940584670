import React from 'react'
import {graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Box,Flex,Heading,Text} from '@chakra-ui/react'
import MySEO from '../components/seo'

const Styleguide = ({data}) => {

return(
    <>
    <MySEO
        title="Styleguide"
        description="Beispiel für einen Styleguide"
      />
    <Heading># blätterbares</Heading>

    <Text variant="bildtext"><b>Für einen markanten Auftritt, egal in welchem Medium. </b>Wenn mehrere oder häufig wechselnde Mitarbeiter Material für ein Unternehmen oder eine Institution erstellen, bedarf es eines Styleguides, der Gestaltungsrichtlinien von Größe und Position des LOGOS, über HAUSFARBEN, SCHRIFTEN, bis hin zu RASTERN und GRAFIKEN vorgibt. Hier beispielsweise ein übersichtliches Manual im Format 150 x 155 mm, 32 Seiten, erstellt für die Beuth Hochschule Berlin.</Text>
       <Flex>

           <Box width={["100%","100%","49%"]}>
                <GatsbyImage alt="" image= {data.bilder.edges[0].node.childImageSharp.gatsbyImageData} />

           </Box>

       <Box width={["100%","100%","49%"]}>
            <GatsbyImage alt="" image= {data.bilder.edges[1].node.childImageSharp.gatsbyImageData} />
       </Box>

     </Flex>
    </>
)}


export const StyleQuery = graphql`
 query {
  bilder:allFile(filter: {absolutePath: {regex: "/_styleguide/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
 }


`

  export default Styleguide
